import Image from "next/image";
import NoImageImage from "@/app/assets/images/img/placeholder.png";
import Link from "next/link";

interface CompressedImageProps {
	src: string | undefined;
	altText: string;
	height?: number;
	width?: number;
	format?: string;
	extraParams?: string;
	imgLink?: string;
	ariaLabel?: string;
	containerClass?: string;
	withNextImage?: boolean;
	onClick?: () => void;
	loading?: "lazy" | "eager" | undefined;
	fetchPriority?: "high" | "low" | undefined;
}

const CompressedImage = ({
	src,
	height,
	width,
	format = "webp",
	altText = "",
	extraParams = "",
	imgLink,
	ariaLabel,
	containerClass,
	withNextImage = true,
	onClick,
	loading = "lazy",
	fetchPriority = "low",
	...props
}: CompressedImageProps) => {
	const getImage = () => {
		let updatedSrc = src;

		// Handle no image
		if (!updatedSrc || updatedSrc.includes("ProductDefault"))
			updatedSrc = NoImageImage.src;

		if (!withNextImage) {
			return (
				<img
					src={updatedSrc}
					alt={altText}
					width={width}
					height={height}
					loading={loading}
					fetchPriority={fetchPriority}
					{...props}
				/>
			);
		}

		if (width === undefined && height === undefined) {
			return <img src={updatedSrc} alt={altText} />;
		}

		return (
			<Image
				src={updatedSrc as string}
				width={width}
				height={height}
				alt={altText}
				onClick={onClick}
				loading={loading}
				priority={fetchPriority === "high"}
				fetchPriority={fetchPriority}
				{...props}
			/>
		);
	};

	if (imgLink)
		return (
			<Link
				href={imgLink}
				className={containerClass}
				aria-label={ariaLabel}
				onClick={onClick}
				{...props}
			>
				{getImage()}
			</Link>
		);

	return <div className={containerClass}>{getImage()}</div>;
};

export default CompressedImage;
